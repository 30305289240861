import { arrayOperations } from '@utility';
import uuid from 'uuid/v4';

export const GET_CUSTOM_MENUS_BY_BRAND_REQUESTED = 'customMenu/GET_CUSTOM_MENUS_BY_BRAND_REQUESTED';
export const GET_CUSTOM_MENUS_BY_BRAND_SUCCESS = 'customMenu/GET_CUSTOM_MENUS_BY_BRAND_SUCCESS';
export const GET_CUSTOM_MENUS_BY_BRAND_FAILURE = 'customMenu/GET_CUSTOM_MENUS_BY_BRAND_FAILURE';

export const GET_SCREENS_BY_MENU_REQUESTED = 'customMenu/GET_SCREENS_BY_MENU_REQUESTED';
export const GET_SCREENS_BY_MENU_SUCCESS = 'customMenu/GET_SCREENS_BY_MENU_SUCCESS';
export const GET_SCREENS_BY_MENU_FAILURE = 'customMenu/GET_SCREENS_BY_MENU_FAILURE';

export const GET_MENUITEMS_BY_SCREEN_REQUESTED = 'customMenu/GET_MENUITEMS_BY_SCREEN_REQUESTED';
export const GET_MENUITEMS_BY_SCREEN_SUCCESS = 'customMenu/GET_MENUITEMS_BY_SCREEN_SUCCESS';
export const GET_MENUITEMS_BY_SCREEN_FAILURE = 'customMenu/GET_MENUITEMS_BY_SCREEN_FAILURE';

export const GET_ALL_MENUITEMS_BY_BRAND_REQUESTED = 'customMenu/GET_ALL_MENUITEMS_BY_BRAND_REQUESTED';
export const GET_ALL_MENUITEMS_BY_BRAND_SUCCESS = 'customMenu/GET_ALL_MENUITEMS_BY_BRAND_SUCCESS';
export const GET_ALL_MENUITEMS_BY_BRAND_FAILURE = 'customMenu/GET_ALL_MENUITEMS_BY_BRAND_FAILURE';

export const GET_ALL_MODGROUPS_BY_BRAND_REQUESTED = 'customMenu/GET_ALL_MODGROUPS_BY_BRAND_REQUESTED';
export const GET_ALL_MODGROUPS_BY_BRAND_SUCCESS = 'customMenu/GET_ALL_MODGROUPS_BY_BRAND_SUCCESS';
export const GET_ALL_MODGROUPS_BY_BRAND_FAILURE = 'customMenu/GET_ALL_MODGROUPS_BY_BRAND_FAILURE';

export const GET_ALL_MODIFIERS_BY_BRAND_REQUESTED = 'customMenu/GET_ALL_MODIFIERS_BY_BRAND_REQUESTED';
export const GET_ALL_MODIFIERS_BY_BRAND_SUCCESS = 'customMenu/GET_ALL_MODIFIERS_BY_BRAND_SUCCESS';
export const GET_ALL_MODIFIERS_BY_BRAND_FAILURE = 'customMenu/GET_ALL_MODIFIERS_BY_BRAND_FAILURE';

export const GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_REQUESTED = 'customMenu/GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_REQUESTED';
export const GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_SUCCESS = 'customMenu/GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_SUCCESS';
export const GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_FAILURE = 'customMenu/GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_FAILURE';

export const GET_MODGROUPS_BY_MODIFIER_REQUESTED = 'customMenu/GET_MODGROUPS_BY_MODIFIER_REQUESTED';
export const GET_MODGROUPS_BY_MODIFIER_SUCCESS = 'customMenu/GET_MODGROUPS_BY_MODIFIER_SUCCESS';
export const GET_MODGROUPS_BY_MODIFIER_FAILURE = 'customMenu/GET_MODGROUPS_BY_MODIFIER_FAILURE';

export const REORDER_REQUESTED = 'customMenu/REORDER_REQUESTED';
export const REORDER_SUCCESS = 'customMenu/REORDER_SUCCESS';
export const REORDER_FAILURE = 'customMenu/REORDER_FAILURE';

export const CLEAR_MENU_DATA = 'customMenu/CLEAR_MENU_DATA';
export const CLEAR_SCREEN_DATA = 'customMenu/CLEAR_SCREEN_DATA';
export const CLEAR_MENUITEM_DATA = 'customMenu/CLEAR_MENUITEM_DATA';
// export const CLEAR_MODIFIER_DATA = 'customMenu/CLEAR_MODIFIER_DATA';

export const GET_MODIFERS_BY_MENUITEM_REQUESTED = 'customMenu/GET_MODIFERS_BY_MENUITEM_REQUESTED';
export const GET_MODIFERS_BY_MENUITEM_SUCCESS = 'customMenu/GET_MODIFERS_BY_MENUITEM_SUCCESS';
export const GET_MODIFERS_BY_MENUITEM_FAILURE = 'customMenu/GET_MODIFERS_BY_MENUITEM_FAILURE';

export const GET_MODGROUPS_BY_MENUITEM_REQUESTED = 'customMenu/GET_MODGROUPS_BY_MENUITEM_REQUESTED';
export const GET_MODGROUPS_BY_MENUITEM_SUCCESS = 'customMenu/GET_MODGROUPS_BY_MENUITEM_SUCCESS';
export const GET_MODGROUPS_BY_MENUITEM_FAILURE = 'customMenu/GET_MODGROUPS_BY_MENUITEM_FAILURE';

export const GET_MODIFIER_BY_MODGROUP_REQUESTED = 'customMenu/GET_MODIFIER_BY_MODGROUP_REQUESTED';
export const GET_MODIFIER_BY_MODGROUP_SUCCESS = 'customMenu/GET_MODIFIER_BY_MODGROUP_SUCCESS';
export const GET_MODIFIER_BY_MODGROUP_FAILURE = 'customMenu/GET_MODIFIER_BY_MODGROUP_FAILURE';

export const GET_BRAND_MODIFIERS_REQUESTED = 'customMenu/GET_BRAND_MODIFIERS_REQUESTED';
export const GET_BRAND_MODIFIERS_SUCCESS = 'customMenu/GET_BRAND_MODIFIERS_SUCCESS';
export const GET_BRAND_MODIFIERS_FAILURE = 'customMenu/GET_BRAND_MODIFIERS_FAILURE';

export const GET_BROWSER_MENUS_BY_BRAND_REQUESTED = 'customMenu/GET_BROWSER_MENUS_BY_BRAND_REQUESTED';
export const GET_BROWSER_MENUS_BY_BRAND_SUCCESS = 'customMenu/GET_BROWSER_MENUS_BY_BRAND_SUCCESS';
export const GET_BROWSER_MENUS_BY_BRAND_FAILURE = 'customMenu/GET_BROWSER_MENUS_BY_BRAND_FAILURE';

export const UPDATE_EXISTING_MENUITEMS = 'customMenu/UPDATE_EXISTING_MENUITEMS';

export const GET_MODGROUPS_BY_BRANDID_REQUESTED = 'customMenu/GET_MODGROUPS_BY_BRANDID_REQUESTED';
export const GET_MODGROUPS_BY_BRANDID_SUCCESS = 'customMenu/GET_MODGROUPS_BY_BRANDID_SUCCESS';
export const GET_MODGROUPS_BY_BRANDID_FAILURE = 'customMenu/GET_MODGROUPS_BY_BRANDID_FAILURE';


export const GET_MODIFIERS_BY_MODGROUP_ID_REQUESTED = 'customMenu/GET_MODIFIERS_BY_MODGROUP_ID_REQUESTED';
export const GET_MODIFIERS_BY_MODGROUP_ID_SUCCESS = 'customMenu/GET_MODIFIERS_BY_MODGROUP_ID_SUCCESS';
export const GET_MODIFIERS_BY_MODGROUP_ID_FAILURE = 'customMenu/GET_MODIFIERS_BY_MODGROUP_ID_FAILURE';

export const GET_MENUITEM_LINKS_REQUESTED = 'customMenu/GET_MENUITEM_LINKS_REQUESTED';
export const GET_MENUITEM_LINKS_SUCCESS = 'customMenu/GET_MENUITEM_LINKS_SUCCESS';
export const GET_MENUITEM_LINKS_FAILURE = 'customMenu/GET_MENUITEM_LINKS_FAILURE';

export const UPDATE_MENUITEM_LINKS_REQUESTED = 'customMenu/UPDATE_MENUITEM_LINKS_REQUESTED';
export const UPDATE_MENUITEM_LINKS_SUCCESS = 'customMenu/UPDATE_MENUITEM_LINKS_SUCCESS';
export const UPDATE_MENUITEM_LINKS_FAILURE = 'customMenu/UPDATE_MENUITEM_LINKS_FAILURE';

export const UPDATE_MENU_ITEMS_LIST = 'customMenu/UPDATE_MENU_ITEMS_LIST';

export const UPDATE_MENULINK_TO_MENUITEM_LIST = 'customMenu/UPDATE_MENULINK_TO_MENUITEM_LIST';

export const UPDATE_MENU_TYPE = 'customMenu/UPDATE_MENU_TYPE';

export const UPDATE_LINKED_EXISTING_MENU_ITEMS_LIST = 'customMenu/UPDATE_LINKED_EXISTING_MENU_ITEMS_LIST';

export const ADD_MODIFIER_IMAGES_REQUESTED = 'customMenu/ADD_MODIFIER_IMAGES_REQUESTED';
export const ADD_MODIFIER_IMAGES_SUCCESS = 'customMenu/ADD_MODIFIER_IMAGES_SUCCESS';
export const ADD_MODIFIER_IMAGES_FAILURE = 'customMenu/ADD_MODIFIER_IMAGES_FAILURE';

export const DELETE_MODIFIER_IMAGES_REQUESTED = 'menuItem/DELETE_MODIFIER_IMAGES_REQUESTED';
export const DELETE_MODIFIER_IMAGES_SUCCESS = 'menuItem/DELETE_MODIFIER_IMAGES_SUCCESS';
export const DELETE_MODIFIER_IMAGES_FAILURE = 'menuItem/DELETE_MODIFIER_IMAGES_FAILURE';

export const GET_MODIFIER_INCLUDEID_REQUESTED = 'customMenu/GET_MODIFIER_INCLUDEID_REQUESTED';
export const GET_MODIFIER_INCLUDEID_SUCCESS = 'customMenu/GET_MODIFIER_INCLUDEID_SUCCESS';
export const GET_MODIFIER_INCLUDEID_FAILURE = 'customMenu/GET_MODIFIER_INCLUDEID_FAILURE';

export const GET_SATES_BY_COUNTRY_REQUESTED = 'customMenu/GET_SATES_BY_COUNTRY_REQUESTED';
export const GET_SATES_BY_COUNTRY_SUCCESS = 'customMenu/GET_SATES_BY_COUNTRY_SUCCESS';
export const GET_SATES_BY_COUNTRY_FAILURE = 'customMenu/GET_SATES_BY_COUNTRY_FAILURE';

export const GET_MENU_PRICE_CHECK_STATUS_REQUESTED = 'menu/GET_MENU_PRICE_CHECK_STATUS_REQUESTED';
export const GET_MENU_PRICE_CHECK_STATUS_SUCCESS = 'menu/GET_MENU_PRICE_CHECK_STATUS_SUCCESS';
export const GET_MENU_PRICE_CHECK_STATUS_FAILURE = 'menu/GET_MENU_PRICE_CHECK_STATUS_FAILURE';

export const GET_MENU_PRICE_CHECK_SCHEDULE_REQUESTED = 'menu/GET_MENU_PRICE_CHECK_SCHEDULE_REQUESTED';
export const GET_MENU_PRICE_CHECK_SCHEDULE_SUCCESS = 'menu/GET_MENU_PRICE_CHECK_SCHEDULE_SUCCESS';
export const GET_MENU_PRICE_CHECK_SCHEDULE_FAILURE = 'menu/GET_MENU_PRICE_CHECK_SCHEDULE_FAILURE';

export const GET_RECIPE_ACTION_REQUESTED = 'menu/GET_RECIPE_ACTION_REQUESTED';
export const GET_RECIPE_ACTION_SUCCESS = 'menu/GET_RECIPE_ACTION_SUCCESS';
export const GET_RECIPE_ACTION_FAILURE = 'menu/GET_RECIPE_ACTION_FAILURE';

export const UPLOAD_RECIPE_ACTION_REQUESTED = 'menu/UPLOAD_RECIPE_ACTION_REQUESTED';
export const UPLOAD_RECIPE_ACTION_SUCCESS = 'menu/UPLOAD_RECIPE_ACTION_SUCCESS';
export const UPLOAD_RECIPE_ACTION_FAILURE = 'menu/UPLOAD_RECIPE_ACTION_FAILURE';

export const DELETE_RECIPE_ACTION_REQUESTED = 'menu/DELETE_RECIPE_ACTION_REQUESTED';
export const DELETE_RECIPE_ACTION_SUCCESS = 'menu/DELETE_RECIPE_ACTION_SUCCESS';
export const DELETE_RECIPE_ACTION_FAILURE = 'menu/DELETE_RECIPE_ACTION_FAILURE';

export const GET_MODIFIER_RECIPE_ACTION_REQUESTED = 'menu/GET_MODIFIER_RECIPE_ACTION_REQUESTED';
export const GET_MODIFIER_RECIPE_ACTION_SUCCESS = 'menu/GET_MODIFIER_RECIPE_ACTION_SUCCESS';
export const GET_MODIFIER_RECIPE_ACTION_FAILURE = 'menu/GET_MODIFIER_RECIPE_ACTION_FAILURE';

export const UPLOAD_MODIFIER_RECIPE_ACTION_REQUESTED = 'menu/UPLOAD_MODIFIER_RECIPE_ACTION_REQUESTED';
export const UPLOAD_MODIFIER_RECIPE_ACTION_SUCCESS = 'menu/UPLOAD_MODIFIER_RECIPE_ACTION_SUCCESS';
export const UPLOAD_MODIFIER_RECIPE_ACTION_FAILURE = 'menu/UPLOAD_MODIFIER_RECIPE_ACTION_FAILURE';

export const DELETE_MODIFIER_RECIPE_ACTION_REQUESTED = 'menu/DELETE_MODIFIER_RECIPE_ACTION_REQUESTED';
export const DELETE_MODIFIER_RECIPE_ACTION_SUCCESS = 'menu/DELETE_MODIFIER_RECIPE_ACTION_SUCCESS';
export const DELETE_MODIFIER_RECIPE_ACTION_FAILURE = 'menu/DELETE_MODIFIER_RECIPE_ACTION_FAILURE';


const initialState = {
  loading: false,
  loaded: false,
  error: false,
  customMenuListByBrand: [],
  browserMenuListByBrand: [],
  screenListByMenu: [],
  menuItemsListByScreen: [],
  modGroupsListByMenuItem: [],
  modifiersListByModGroup: [],
  modGroupsListByModifier: [],
  menuItemsByBrandId: [],
  modGroupsListByBrandIdMenuItemId: [],
  modifiersListByBrandIdModGroupId: [],
  modGroupsListByBrandIdModifierId: [],
  modifiersListByMenuItem: [],
  brandModifiersListLookup: [],
  modifierGroupByBrandId: [],
  modifierBymodgroupIdList: [],
  menuItemLinks: [],
  modGroupsLinks: [],
  menuType: '',
  selectedLinkedMenuItem: null,
  modifierIncludeId: [],
  getStates: [],
  priceCheckStatus: null,
  priceCheckLoading: false, // introduced to manipulate loaders specifically for Basket Testers price check APIs
  recipeUrl: null,
  modifierRecipeUrl: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOM_MENUS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_CUSTOM_MENUS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        customMenuListByBrand: action.result,
      };
    }
    case GET_CUSTOM_MENUS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        customMenuListByBrand: [],
      };
    }
    case GET_BROWSER_MENUS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BROWSER_MENUS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        browserMenuListByBrand: action.result,
      };
    }
    case GET_BROWSER_MENUS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        browserMenuListByBrand: [],
      };
    }
    case GET_SCREENS_BY_MENU_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_SCREENS_BY_MENU_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        screenListByMenu: action.result,
      };
    }
    case GET_SCREENS_BY_MENU_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        screenListByMenu: [],
      };
    }
    case GET_MENUITEMS_BY_SCREEN_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MENUITEMS_BY_SCREEN_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        menuItemsListByScreen: action.result.map(obj => ({ ...obj, imageUrl: `${obj.imageUrl}?${uuid()}` })),
      };
    }
    case GET_MENUITEMS_BY_SCREEN_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        menuItemsListByScreen: [],
      };
    }
    case GET_MODGROUPS_BY_MODIFIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODGROUPS_BY_MODIFIER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modGroupsListByModifier: action.result,
      };
    }
    case GET_MODGROUPS_BY_MODIFIER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modGroupsListByModifier: [],
      };
    }
    case GET_ALL_MENUITEMS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ALL_MENUITEMS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        menuItemsByBrandId: action.result,
      };
    }
    case GET_ALL_MENUITEMS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        menuItemsByBrandId: [],
      };
    }
    case GET_ALL_MODGROUPS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ALL_MODGROUPS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modGroupsListByBrandIdMenuItemId: action.result,
      };
    }
    case GET_ALL_MODGROUPS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modGroupsListByBrandIdMenuItemId: [],
      };
    }
    case GET_ALL_MODIFIERS_BY_BRAND_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ALL_MODIFIERS_BY_BRAND_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modGroupsListByBrandIdModifierId: action.result,
      };
    }
    case GET_ALL_MODIFIERS_BY_BRAND_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modGroupsListByBrandIdModifierId: [],
      };
    }
    case GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modGroupsListByBrandIdModifierId: action.result,
      };
    }
    case GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modGroupsListByBrandIdModifierId: [],
      };
    }
    case GET_MODIFERS_BY_MENUITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODIFERS_BY_MENUITEM_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modifiersListByMenuItem: action.result,
      };
    }
    case GET_MODIFERS_BY_MENUITEM_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modifiersListByMenuItem: [],
      };
    }
    case GET_MODGROUPS_BY_MENUITEM_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODGROUPS_BY_MENUITEM_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modGroupsListByMenuItem: action.result,
      };
    }
    case GET_MODGROUPS_BY_MENUITEM_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modGroupsListByMenuItem: [],
      };
    }
    case GET_MODIFIER_BY_MODGROUP_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODIFIER_BY_MODGROUP_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modifiersListByModGroup: action.result,
      };
    }
    case GET_MODIFIER_BY_MODGROUP_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modifiersListByModGroup: [],
      };
    }
    case GET_MODIFIERS_BY_MODGROUP_ID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODIFIERS_BY_MODGROUP_ID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modifierBymodgroupIdList: action.result,
      };
    }
    case GET_MODIFIERS_BY_MODGROUP_ID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modifierBymodgroupIdList: [],
      };
    }
    case GET_BRAND_MODIFIERS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_BRAND_MODIFIERS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        brandModifiersListLookup: action.result,
      };
    }
    case GET_BRAND_MODIFIERS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        brandModifiersListLookup: [],
      };
    }
    case CLEAR_MENU_DATA: {
      return {
        ...state,
        screenListByMenu: [],
        menuItemsListByScreen: [],
        modGroupsListByMenuItem: [],
      };
    }
    case CLEAR_SCREEN_DATA: {
      return {
        ...state,
        menuItemsListByScreen: [],
        modGroupsListByMenuItem: [],
      };
    }
    case CLEAR_MENUITEM_DATA: {
      return {
        ...state,
        modGroupsListByMenuItem: [],
      };
    }
    /* case CLEAR_MODIFIER_DATA: {
      return {
        ...state,
        modGroupsListByMenuItem: [],
      };
    } */
    case UPDATE_EXISTING_MENUITEMS: {
      return {
        ...state,
        menuItemsByBrandId: arrayOperations.modifyObjectOfArray(state.menuItemsByBrandId, action.payload),
      };
    }
    case GET_MODGROUPS_BY_BRANDID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODGROUPS_BY_BRANDID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modifierGroupByBrandId: action.result,
      };
    }
    case GET_MODGROUPS_BY_BRANDID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        brandModifiersListLookup: [],
      };
    }
    case REORDER_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REORDER_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case REORDER_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_MENUITEM_LINKS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MENUITEM_LINKS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        menuItemLinks: action.result,
      };
    }
    case GET_MENUITEM_LINKS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        brandModifiersListLookup: [],
      };
    }
    case UPDATE_MENUITEM_LINKS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_MENUITEM_LINKS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_MENUITEM_LINKS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_MENU_ITEMS_LIST: {
      const updatedMenuItemsList = state.menuItemsListByScreen.map((e) => {
        if (e.id === action.key) {
          return { ...e, menuLinks: action.payload };
        } return e;
      });
      return {
        ...state,
        loaded: true,
        loading: false,
        menuItemsListByScreen: updatedMenuItemsList,
        selectedLinkedMenuItem: action.key,
      };
    }
    case UPDATE_LINKED_EXISTING_MENU_ITEMS_LIST: {
      const updatedLinkedMenuItemsList = state.menuItemsListByScreen.map((e) => {
        if (e.id === state.selectedLinkedMenuItem) {
          const temp = e.menuLinks && e.menuLinks.length > 0 && e.menuLinks.map((val) => {
            if (val.linkedMenuItemId === action.key) {
              return { ...val, linkedMenuItem: action.payload };
            } return val;
          });
          return { ...e, menuLinks: temp };
        } return e;
      });
      return {
        ...state,
        loaded: true,
        loading: false,
        menuItemsListByScreen: updatedLinkedMenuItemsList,
      };
    }
    case UPDATE_MENULINK_TO_MENUITEM_LIST: {
      const updatedMenuItemsList = state.menuItemsListByScreen.map((e) => {
        if (e.id === action.menuItemId) {
          return { ...e, menuLinks: action.payload };
        } return e;
      });
      return {
        ...state,
        loaded: true,
        loading: false,
        menuItemsListByScreen: updatedMenuItemsList
      };
    }
    case UPDATE_MENU_TYPE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        menuType: action.payload
      };
    }
    case ADD_MODIFIER_IMAGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_MODIFIER_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case ADD_MODIFIER_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case DELETE_MODIFIER_IMAGES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_MODIFIER_IMAGES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MODIFIER_IMAGES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MODIFIER_INCLUDEID_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_MODIFIER_INCLUDEID_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        modifierIncludeId: action.result,
      };
    }
    case GET_MODIFIER_INCLUDEID_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
        modifierIncludeId: [],
      };
    }
    case GET_SATES_BY_COUNTRY_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        getStates: [],
      };
    }
    case GET_SATES_BY_COUNTRY_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
        getStates: action.result,
      };
    }
    case GET_SATES_BY_COUNTRY_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_MENU_PRICE_CHECK_STATUS_REQUESTED: {
      return {
        ...state,
        // loading: true,
        priceCheckLoading: true,
        loaded: false,
      };
    }
    case GET_MENU_PRICE_CHECK_STATUS_SUCCESS: {
      return {
        ...state,
        // loading: false,
        priceCheckLoading: false,
        loaded: true,
        error: false,
        priceCheckStatus: action.result,
      };
    }
    case GET_MENU_PRICE_CHECK_STATUS_FAILURE: {
      return {
        ...state,
        // loading: false,
        priceCheckLoading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_MENU_PRICE_CHECK_SCHEDULE_REQUESTED: {
      return {
        ...state,
        // loading: true,
        priceCheckLoading: true,
        loaded: false,
      };
    }
    case GET_MENU_PRICE_CHECK_SCHEDULE_SUCCESS: {
      return {
        ...state,
        // loading: false,
        priceCheckLoading: false,
        loaded: true,
        error: false,
        priceCheckStatus: action.result,
      };
    }
    case GET_MENU_PRICE_CHECK_SCHEDULE_FAILURE: {
      return {
        ...state,
        // loading: false,
        priceCheckLoading: false,
        loaded: true,
        error: true,
      };
    }
    case GET_RECIPE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
        recipeUrl: null,
      };
    }
    case GET_RECIPE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        recipeUrl: action.result,
      };
    }
    case GET_RECIPE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPLOAD_RECIPE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_RECIPE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_RECIPE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_RECIPE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case DELETE_RECIPE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_RECIPE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case GET_MODIFIER_RECIPE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
        modifierRecipeUrl: null,
      };
    }
    case GET_MODIFIER_RECIPE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        modifierRecipeUrl: action.result,
      };
    }
    case GET_MODIFIER_RECIPE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case UPLOAD_MODIFIER_RECIPE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_MODIFIER_RECIPE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case UPLOAD_MODIFIER_RECIPE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    case DELETE_MODIFIER_RECIPE_ACTION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MODIFIER_RECIPE_ACTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
      };
    }
    case DELETE_MODIFIER_RECIPE_ACTION_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
      };
    }
    default: return state;
  }
};

export const getCustomMenuListByBrandId = (brandId) => {
  return {
    types: [GET_CUSTOM_MENUS_BY_BRAND_REQUESTED, GET_CUSTOM_MENUS_BY_BRAND_SUCCESS, GET_CUSTOM_MENUS_BY_BRAND_FAILURE],
    promise: client => client.get(`getCustomMenusByBrandId/${brandId}`)
  };
};
export const getBrowserMenuListByBrandId = (brandId) => {
  return {
    types: [GET_BROWSER_MENUS_BY_BRAND_REQUESTED, GET_BROWSER_MENUS_BY_BRAND_SUCCESS, GET_BROWSER_MENUS_BY_BRAND_FAILURE],
    promise: client => client.get(`getBrowserMenuListByBrandId/${brandId}`)
  };
};

export const getScreenListByMenuId = (menuId) => {
  return {
    types: [GET_SCREENS_BY_MENU_REQUESTED, GET_SCREENS_BY_MENU_SUCCESS, GET_SCREENS_BY_MENU_FAILURE],
    promise: client => client.get(`getScreenByMenuId/${menuId}`)
  };
};

export const getMenuItemsListByScreenId = (screenId) => {
  return {
    types: [GET_MENUITEMS_BY_SCREEN_REQUESTED, GET_MENUITEMS_BY_SCREEN_SUCCESS, GET_MENUITEMS_BY_SCREEN_FAILURE],
    promise: client => client.get(`getMenuItemsByScreenId/${screenId}`)
  };
};

export const getModGroupListByModifier = (modifierId) => {
  return {
    types: [GET_MODGROUPS_BY_MODIFIER_REQUESTED, GET_MODGROUPS_BY_MODIFIER_SUCCESS, GET_MODGROUPS_BY_MODIFIER_FAILURE],
    promise: client => client.get(`getModGroupsByModifierId/${modifierId}`)
  };
};

export const getAllModGroupsByBrandId = (brandId) => {
  return {
    types: [GET_MODGROUPS_BY_BRANDID_REQUESTED, GET_MODGROUPS_BY_BRANDID_SUCCESS, GET_MODGROUPS_BY_BRANDID_FAILURE],
    promise: client => client.get(`getAllModGroupsByBrandId/${brandId}`)
  };
};

export const reOrderItems = (id, sortedIds, type) => {
  let url = '';
  if (type === 'menu') {
    url = `menuReorder/${id}`;
  }
  if (type === 'screen') {
    url = `screenReorder/${id}`;
  }
  if (type === 'menuItemModifier') {
    url = `modifierReorder/${id}`;
  }
  if (type === 'modifire') {
    url = `modiferGroupModiferReorder/${id}`;
  }
  if (type === 'modifireGroup') {
    url = `reorderModifierGroupModifier/${id}`;
  }
  if (type === 'modifierGroupLink') {
    url = `modifierGroupLinkReorder/${id}`;
  }
  if (type === 'menuItemLink') {
    url = `menuItemLinkReorder/${id}`;
  }
  if (type === 'BrowsableMenu') {
    url = 'reOrderMenu';
  }
  return {
    types: [REORDER_REQUESTED, REORDER_SUCCESS, REORDER_FAILURE],
    promise: client => client.post(url, { data: sortedIds }),
    payload: type
  };
};

export const reOrderSortItems = (id, type, payload) => {
  let url = '';
  if (type === 'menu') {
    url = `menuReorder/${id}`;
  }
  if (type === 'screen') {
    url = `screenReorder/${id}`;
  }
  if (type === 'modifire') {
    url = `modiferGroupModiferReorder/${id}`;
  }
  if (type === 'modifireGroup') {
    url = `reorderModifierGroupModifier/${id}`;
  }
  if (type === 'modifierGroupLink') {
    url = `modifierGroupLinkReorder/${id}`;
  }
  return {
    types: [REORDER_REQUESTED, REORDER_SUCCESS, REORDER_FAILURE],
    promise: client => client.post(url, { data: payload }),
  };
};
export const getAllMenuItemsByBrandId = (brandId) => {
  return {
    types: [GET_ALL_MENUITEMS_BY_BRAND_REQUESTED, GET_ALL_MENUITEMS_BY_BRAND_SUCCESS, GET_ALL_MENUITEMS_BY_BRAND_FAILURE],
    promise: client => client.get(`getAllMenuItemsByBrandId/${brandId}`)
  };
};

export const getAllModGroupsByBrandIdMenuItemId = (brandId, menuItemId) => {
  return {
    types: [GET_ALL_MODGROUPS_BY_BRAND_REQUESTED, GET_ALL_MODGROUPS_BY_BRAND_SUCCESS, GET_ALL_MODGROUPS_BY_BRAND_FAILURE],
    promise: client => client.get(`getAllModGroupsByBrandId/${brandId}/menuItemId/${menuItemId}`)
  };
};

export const getAllModifiersByBrandIdModGroupId = (brandId, modGroupId) => {
  return {
    types: [GET_ALL_MODIFIERS_BY_BRAND_REQUESTED, GET_ALL_MODIFIERS_BY_BRAND_SUCCESS, GET_ALL_MODIFIERS_BY_BRAND_FAILURE],
    promise: client => client.get(`getAllModifiersByBrandId/${brandId}/modifierGroupId/${modGroupId}`)
  };
};

export const getAllModGroupsByBrandIdModifierId = (brandId, modifierId) => {
  return {
    types: [GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_REQUESTED, GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_SUCCESS, GET_ALL_MODGROUPS_BY_BRAND_MODIFIER_FAILURE],
    promise: client => client.get(`getAllModGroupsByBrandIdModifier/${brandId}/modifierId/${modifierId}`)
  };
};

export const getModifiersByMenuItem = (menuItemId) => {
  return {
    types: [GET_MODIFERS_BY_MENUITEM_REQUESTED, GET_MODIFERS_BY_MENUITEM_SUCCESS, GET_MODIFERS_BY_MENUITEM_FAILURE],
    promise: client => client.get(`getModifiersByMenuItem/${menuItemId}`)
  };
};

export const resetMenusData = () => {
  return {
    type: CLEAR_MENU_DATA,
  };
};

export const resetScreensData = () => {
  return {
    type: CLEAR_SCREEN_DATA,
  };
};

export const resetMenuItemsData = () => {
  return {
    type: CLEAR_MENUITEM_DATA,
  };
};

/* export const resetModifierData = () => {
  return {
    type: CLEAR_MODIFIER_DATA,
  };
}; */

export const getModGroupsListByMenuItem = (menuItemId) => {
  return {
    types: [GET_MODGROUPS_BY_MENUITEM_REQUESTED, GET_MODGROUPS_BY_MENUITEM_SUCCESS, GET_MODGROUPS_BY_MENUITEM_FAILURE],
    promise: client => client.get(`getModGroupsBymenuItemId/${menuItemId}`)
  };
};

export const getModifiersListByModGroup = (modGroupId) => {
  return {
    types: [GET_MODIFIER_BY_MODGROUP_REQUESTED, GET_MODIFIER_BY_MODGROUP_SUCCESS, GET_MODIFIER_BY_MODGROUP_FAILURE],
    promise: client => client.get(`getModifiersByModGroupId/${modGroupId}`)
  };
};

export const getModifierBymodGroupId = (modGroupId) => {
  return {
    types: [GET_MODIFIERS_BY_MODGROUP_ID_REQUESTED, GET_MODIFIERS_BY_MODGROUP_ID_SUCCESS, GET_MODIFIERS_BY_MODGROUP_ID_FAILURE],
    promise: client => client.get(`getModifiersByModGroupId/${modGroupId}`)
  };
};

export const getBrandModofiers = (brandId) => {
  return {
    types: [GET_BRAND_MODIFIERS_REQUESTED, GET_BRAND_MODIFIERS_SUCCESS, GET_BRAND_MODIFIERS_FAILURE],
    promise: client => client.get(`getBrandModifiers/${brandId}`)
  };
};

export const updateExistingMenuItems = (data) => {
  return {
    type: UPDATE_EXISTING_MENUITEMS,
    payload: data
  };
};

export const getMenuItemLink = (menuItemID) => {
  return {
    types: [GET_MENUITEM_LINKS_REQUESTED, GET_MENUITEM_LINKS_SUCCESS, GET_MENUITEM_LINKS_FAILURE],
    promise: client => client.get(`getMenuItemLinks/${menuItemID}`)
  };
};

export const updateMenuItemLink = (data) => {
  return {
    types: [UPDATE_MENUITEM_LINKS_REQUESTED, UPDATE_MENUITEM_LINKS_SUCCESS, UPDATE_MENUITEM_LINKS_FAILURE],
    promise: client => client.post('updateMenuItemLinks', {
      data
    })
  };
};

export const updateMenuItemList = (data, key) => {
  return (dispatch) => {
    dispatch(
      {
        type: UPDATE_MENU_ITEMS_LIST,
        payload: data,
        key,
      }
    );
  };
};

export const updateLinkedExistingMenuItemList = (key, data) => {
  return (dispatch) => {
    dispatch(
      {
        type: UPDATE_LINKED_EXISTING_MENU_ITEMS_LIST,
        payload: data,
        key,
      }
    );
  };
};

export const updateMenuLintToMenuItemList = (data, menuItemId) => {
  return (dispatch) => {
    dispatch(
      {
        type: UPDATE_MENULINK_TO_MENUITEM_LIST,
        payload: data,
        menuItemId
      }
    );
  };
};

export const updateMenuType = (data) => {
  return (dispatch) => {
    dispatch(
      {
        type: UPDATE_MENU_TYPE,
        payload: data
      }
    );
  };
};
export const addModifierImage = (modifierId, data) => {
  return {
    types: [ADD_MODIFIER_IMAGES_REQUESTED, ADD_MODIFIER_IMAGES_SUCCESS, ADD_MODIFIER_IMAGES_FAILURE],
    promise: client => client.post(`addModifierImage/${modifierId}/image/add`, { data }),
  };
};
export const deleteModifierImage = (modifierId) => {
  return {
    types: [DELETE_MODIFIER_IMAGES_REQUESTED, DELETE_MODIFIER_IMAGES_SUCCESS, DELETE_MODIFIER_IMAGES_FAILURE],
    promise: client => client.post(`addModifierImage/${modifierId}/image/delete`),
  };
};
export const getModifierIncludeds = () => {
  return {
    types: [GET_MODIFIER_INCLUDEID_REQUESTED, GET_MODIFIER_INCLUDEID_SUCCESS, GET_MODIFIER_INCLUDEID_FAILURE],
    promise: client => client.get('modifierIncludeds')
  };
};
export const getStatesByCountry = (id) => {
  return {
    types: [GET_SATES_BY_COUNTRY_REQUESTED, GET_SATES_BY_COUNTRY_SUCCESS, GET_SATES_BY_COUNTRY_FAILURE],
    promise: client => client.get(`getStatesByCountry/${id}`)
  };
};

export const getMenuPriceCheckStatus = (data) => {
  return {
    types: [GET_MENU_PRICE_CHECK_STATUS_REQUESTED, GET_MENU_PRICE_CHECK_STATUS_SUCCESS, GET_MENU_PRICE_CHECK_STATUS_FAILURE],
    promise: client => client.post('getPriceCheckStatusMenu', { data })
  };
};

export const getMenuPriceCheckSchedule = (data) => {
  return {
    types: [GET_MENU_PRICE_CHECK_SCHEDULE_REQUESTED, GET_MENU_PRICE_CHECK_SCHEDULE_SUCCESS, GET_MENU_PRICE_CHECK_SCHEDULE_FAILURE],
    promise: client => client.post('getPriceCheckScheduleMenu', { data })
  };
};
export const getRecipeFile = (id) => {
  return {
    types: [GET_RECIPE_ACTION_REQUESTED, GET_RECIPE_ACTION_SUCCESS, GET_RECIPE_ACTION_FAILURE],
    promise: client => client.get(`getRecipePdf/${id}/view`)
  };
};
export const uploadRecipeFile = (id, data) => {
  return {
    types: [UPLOAD_RECIPE_ACTION_REQUESTED, UPLOAD_RECIPE_ACTION_SUCCESS, UPLOAD_RECIPE_ACTION_FAILURE],
    promise: client => client.post(`uploadRecipePdf/${id}/save`, { data })
  };
};

export const deleteRecipeFile = (id) => {
  return {
    types: [DELETE_RECIPE_ACTION_REQUESTED, DELETE_RECIPE_ACTION_SUCCESS, DELETE_RECIPE_ACTION_FAILURE],
    promise: client => client.post(`deleteRecipePdf/${id}/delete`)
  };
};
export const getModifierRecipeFile = (id) => {
  return {
    types: [GET_MODIFIER_RECIPE_ACTION_REQUESTED, GET_MODIFIER_RECIPE_ACTION_SUCCESS, GET_MODIFIER_RECIPE_ACTION_FAILURE],
    promise: client => client.get(`getModiferRecipePdf/${id}/view`)
  };
};
export const uploadModifierRecipeFile = (id, data) => {
  return {
    types: [UPLOAD_MODIFIER_RECIPE_ACTION_REQUESTED, UPLOAD_MODIFIER_RECIPE_ACTION_SUCCESS, UPLOAD_MODIFIER_RECIPE_ACTION_FAILURE],
    promise: client => client.post(`uploadModiferRecipePdf/${id}/save`, { data })
  };
};

export const deleteModifierRecipeFile = (id) => {
  return {
    types: [DELETE_MODIFIER_RECIPE_ACTION_REQUESTED, DELETE_MODIFIER_RECIPE_ACTION_SUCCESS, DELETE_MODIFIER_RECIPE_ACTION_FAILURE],
    promise: client => client.post(`deleteModiferRecipePdf/${id}/delete`)
  };
};
