export const GET_EXTERNAL_INTEGRATIONS_REQUESTED = 'externalIntegrations/GET_EXTERNAL_INTEGRATIONS_REQUESTED';
export const GET_EXTERNAL_INTEGRATIONS_SUCCESS = 'externalIntegrations/GET_EXTERNAL_INTEGRATIONS_SUCCESS';
export const GET_EXTERNAL_INTEGRATIONS_FAILURE = 'externalIntegrations/GET_EXTERNAL_INTEGRATIONS_FAILURE';

export const DELETE_EXTERNAL_INTEGRATIONS_REQUESTED = 'externalIntegrations/DELETE_EXTERNAL_INTEGRATIONS_REQUESTED';
export const DELETE_EXTERNAL_INTEGRATIONS_SUCCESS = 'externalIntegrations/DELETE_EXTERNAL_INTEGRATIONS_SUCCESS';
export const DELETE_EXTERNAL_INTEGRATIONS_FAILURE = 'externalIntegrations/DELETE_EXTERNAL_INTEGRATIONS_FAILURE';

export const ADD_EXTERNAL_INTEGRATION_REQUESTED = 'externalIntegrations/ADD_EXTERNAL_INTEGRATION_REQUESTED';
export const ADD_EXTERNAL_INTEGRATION_SUCCESS = 'externalIntegrations/ADD_EXTERNAL_INTEGRATION_SUCCESS';
export const ADD_EXTERNAL_INTEGRATION_FAILURE = 'externalIntegrations/ADD_EXTERNAL_INTEGRATION_FAILURE';

export const UPDATE_EXTERNAL_INTEGRATION_REQUESTED = 'externalIntegrations/UPDATE_EXTERNAL_INTEGRATION_REQUESTED';
export const UPDATE_EXTERNAL_INTEGRATION_SUCCESS = 'externalIntegrations/UPDATE_EXTERNAL_INTEGRATION_SUCCESS';
export const UPDATE_EXTERNAL_INTEGRATION_FAILURE = 'externalIntegrations/UPDATE_EXTERNAL_INTEGRATION_FAILURE';

export const REGENERATE_EXTERNAL_INTEGRATION_REQUESTED = 'externalIntegrations/REGENERATE_EXTERNAL_INTEGRATION_REQUESTED';
export const REGENERATE_EXTERNAL_INTEGRATION_SUCCESS = 'externalIntegrations/REGENERATE_EXTERNAL_INTEGRATION_SUCCESS';
export const REGENERATE_EXTERNAL_INTEGRATION_FAILURE = 'externalIntegrations/REGENERATE_EXTERNAL_INTEGRATION_FAILURE';

export const GET_EXTERNAL_INTEGRATION_TYPES_REQUESTED = 'externalIntegrations/GET_EXTERNAL_INTEGRATION_TYPES_REQUESTED';
export const GET_EXTERNAL_INTEGRATION_TYPES_SUCCESS = 'externalIntegrations/GET_EXTERNAL_INTEGRATION_TYPES_SUCCESS';
export const GET_EXTERNAL_INTEGRATION_TYPES_FAILURE = 'externalIntegrations/GET_EXTERNAL_INTEGRATION_TYPES_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  extIntegrationsList: [],
  externalIntegrationTypesList: [],

};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EXTERNAL_INTEGRATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_EXTERNAL_INTEGRATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        extIntegrationsList: action.result
      };
    }
    case GET_EXTERNAL_INTEGRATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true
      };
    }
    case DELETE_EXTERNAL_INTEGRATIONS_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case DELETE_EXTERNAL_INTEGRATIONS_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case DELETE_EXTERNAL_INTEGRATIONS_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case ADD_EXTERNAL_INTEGRATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case ADD_EXTERNAL_INTEGRATION_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case ADD_EXTERNAL_INTEGRATION_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case UPDATE_EXTERNAL_INTEGRATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case UPDATE_EXTERNAL_INTEGRATION_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case UPDATE_EXTERNAL_INTEGRATION_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case REGENERATE_EXTERNAL_INTEGRATION_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case REGENERATE_EXTERNAL_INTEGRATION_SUCCESS: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: false,
      };
    }
    case REGENERATE_EXTERNAL_INTEGRATION_FAILURE: {
      return {
        ...state,
        loaded: true,
        loading: false,
        error: true,
      };
    }
    case GET_EXTERNAL_INTEGRATION_TYPES_REQUESTED: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }
    case GET_EXTERNAL_INTEGRATION_TYPES_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        externalIntegrationTypesList: action.result,
      };
    }
    case GET_EXTERNAL_INTEGRATION_TYPES_FAILURE: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: true,
      };
    }
    default:
      return state;
  }
};

export const getExternalIntegrationsList = (companyId) => {
  return {
    types: [GET_EXTERNAL_INTEGRATIONS_REQUESTED, GET_EXTERNAL_INTEGRATIONS_SUCCESS, GET_EXTERNAL_INTEGRATIONS_FAILURE],
    promise: client => client.get(`getExternalIntegrations/${companyId}`)
  };
};

export const deleteExternalIntegration = (externalIntegrationId, data) => {
  return {
    types: [DELETE_EXTERNAL_INTEGRATIONS_REQUESTED, DELETE_EXTERNAL_INTEGRATIONS_SUCCESS, DELETE_EXTERNAL_INTEGRATIONS_FAILURE],
    promise: client => client.post(`deleteExternalIntegrations/${externalIntegrationId}`, { data })
  };
};

export const addExternalIntegrationData = (data) => {
  return {
    types: [ADD_EXTERNAL_INTEGRATION_REQUESTED, ADD_EXTERNAL_INTEGRATION_SUCCESS, ADD_EXTERNAL_INTEGRATION_FAILURE],
    promise: client => client.post('addExternalIntegration', { data })
  };
};

export const updateExternalIntegrationData = (data) => {
  return {
    types: [UPDATE_EXTERNAL_INTEGRATION_REQUESTED, UPDATE_EXTERNAL_INTEGRATION_SUCCESS, UPDATE_EXTERNAL_INTEGRATION_FAILURE],
    promise: client => client.post('updateExternalIntegration', { data })
  };
};

export const regenerateExternalIntegration = (externalIntegrationId) => {
  return {
    types: [REGENERATE_EXTERNAL_INTEGRATION_REQUESTED, REGENERATE_EXTERNAL_INTEGRATION_SUCCESS, REGENERATE_EXTERNAL_INTEGRATION_FAILURE],
    promise: client => client.post(`regenerateExternalIntegration/${externalIntegrationId}`)
  };
};
export const getExternalIntegrationTypes = () => {
  return {
    types: [GET_EXTERNAL_INTEGRATION_TYPES_REQUESTED, GET_EXTERNAL_INTEGRATION_TYPES_SUCCESS, GET_EXTERNAL_INTEGRATION_TYPES_FAILURE],
    promise: client => client.get('getExternalIntegrationTypes')
  };
};
